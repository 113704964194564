html
{
    height:100vh;
}

body
{
    height:100vh;
    background-image: url('/images/bg2.png');
    background-repeat: repeat;
}

footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    /*
    div {
        width: 300px;
        height: 50px;
    }
    */
} 

#contentWrapper {
    position: relative;
    min-height: 100vh;
    background-color: white;
    box-shadow: 0px 0px 15px #333;
    border-right: 1px solid #949494;
    border-left: 1px solid #949494;
}

.cursor-pointer {
    cursor: pointer;
}